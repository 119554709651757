<template>
     <CCard class="col-md-12">
      <CRow class="p-3">
        <h4 class="color-light ml-2 page_title"><span class="ul_text">GE</span>NERATE SDK</h4>
       </CRow>
       
    <CCardBody class="generatesdk">
      <div>
        <CRow>
            <!-- <CCol>
                <label>Select Campaign</label>
            </CCol> -->
            <CCol>
                <multiselect
                    class="filter1"
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    track-by="_id"
                    label="campname"
                    :multiple="false"
                    :options="campaigns"
                    :preselect-first="true"
                    :preserve-search="false"
                    placeholder="Select Campaign"
                    v-model="mainobject.campaign"
                    @input="OnSubmit()"
                >
                </multiselect>
            </CCol>
            <CCol>
            <CButton class="" color="success" @click="OnSubmit()">Generate Code</CButton>
            </CCol>
        </CRow>
         <CRow class="ml-1 mt-3">
          <span style="color: red">* </span>
          <span>For full documentation about sdk please goto  <CHeaderNavLink to="/document/sdkdocument">SDK DOCUMENTATION</CHeaderNavLink> page.</span>
        </CRow>
        <CRow class="ml-1">
          <span style="color: red">* </span>
          <span>For server side api call you need API key, please visit <CHeaderNavLink to="/setting/developer">developer</CHeaderNavLink> page to get one.</span>
        </CRow>
        <CRow class="ml-1">
          <span style="color: red">* </span>
          <span>Please note Offer URL / campaign URL make no sense while using SDK because by using SDK you are controlling everything at your side.</span>
        </CRow>
        <CRow class="mt-1 col-md-12">
          <label class="ml-2">Snippet:</label>
          <CTextarea
            id="sdktext"
            v-model="url_data.sdk_data"
            rows="8"
            cols="150"
            type="text"
            class="url_text"
            v-if="sdk_data"
            disabled
          />
        </CRow>
        <CRow class="col-md-12">
          <label class="ml-2">JS url:</label>
          <CTextarea rows="2" v-if="sdk_data" cols="150" v-model="url_data.sdk_url" @input="checkButton(url_data.sdk_url)" class="url_text"/>
        </CRow>
        <CRow class="ml-1">
          <span style="color: red">*</span>
          <span
            >You can Change macros subXX to any macros. For example ?{sub1} =
            {clicktid}</span
          >
        </CRow>
        <CRow class="ml-1">
          <span style="color: red">*</span>
          <span
            >{rev}
            Revenue on Conversion
          </span>
        </CRow>
        <CRow class="ml-1">
            <CButton v-if="sdk_data" color="dark" @click="collapsesdk = !collapsesdk" class="m-1"
            >add SDK Param
        </CButton>
        <CButton v-if="sdk_data" color="dark" class="m-1" @click="copyToClipboard()">
          Copy SDK
        </CButton>
        </CRow>
        <CRow class="ml-1">
        <CCollapse id="collapse-2" :show="collapsesdk">
          <CRow class="ml-1">
            <div
              v-for="(value, index) in sdk_url_param"
              @click="addUrl_Param(index)"
              :key="index"
            >
              <CButton
                style="margin: 2px 2px 5px 2px; width: 70px"
                color="primary"
                variant="outline"
                >{{ value }}</CButton
              >
            </div>
          </CRow>
        </CCollapse>
        </CRow>
      </div>
    </CCardBody>
    <vue-tour :name="'sdk'"></vue-tour>
     </CCard>
</template>

<script>
import CampaignService from '../../services/campaignControlService'
import Multiselect from "vue-multiselect";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Config from "../../services/config";
import VueTour from "../../components/vueTour.vue"
export default {
    name:'GenerateSDK',
    components: {
        Multiselect,VueTour
    },
    computed: {
    sdk_url(){
      return this.url_data.sdk_url;
    }
  },
    watch: {
    sdk_url(){
      this.checkButton(this.url_data.sdk_url);
    }
  },
    data(){
        return{
            show_alert:false,
            collapsesdk:false,
            sdk_data:true,
            campaigns:[],
            mainobject:{
                campaign:''
            },
            url_data:{},
            sdk_url_param: {sub1: "sub1",sub2: "sub2",sub3: "sub3",sub4: "sub4",sub5: "sub5",sub6: "sub6",sub7: "sub7",sub8: "sub8",sub9: "sub9",sub10: "sub10",sub11: "sub11",sub12: "sub12"},
        }
    },
    mounted(){
      if(window.localStorage.getItem('guide_tool') == 'true'){
        this.$tours['sdk'].start()
        var element = document.querySelector('.sdk-menu');
        if(element){element.classList.remove('focus_guide');}
        var classes = ['.create-offer','.traffic-source','.create-campaign','.sdk-menu','.report-menu','.campaign-list']
        classes.map((t)=> {
          var ele = document.querySelector(t);
          if(ele){ele.classList.remove('focus_guide');}
        })
      }
        if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
        else{
        this.getCampaigns();
        if(this.$root.$data.campdata){
          this.mainobject.campaign = this.$root.$data.campdata;
        }
        }
    },
    methods:{
      async getCampaigns(){
        try {
        let response = await CampaignService.get()
        this.campaigns = response.message.campaigns
        } catch (e) {
        // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        // this.errorNotify("Error", "connection error", "danger");
        //   this.$router.push("Login");
        }
       },
      async OnSubmit(){
          this.sdk_data = false;
          this.url_data.sdk_url = ''
        if(this.mainobject.campaign && this.mainobject.campaign._id){
          this.url_data.sdk_url = Config.sdkurl + this.mainobject.campaign._id;
          this.checkButton(this.url_data.sdk_url)
          this.sdk_data = true;
          //Swal.fire({title: 'Success!',text: "SDK Generated",icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 500})
        }else{
          Swal.fire({title: 'Error!',text: "Please Select Campaign",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
        },
    checkButton(url) {
        var params = {"sub1": "sub1","sub2": "sub2","sub3": "sub3","sub4": "sub4","sub5": "sub5",
            "sub6": "sub6","sub7": "sub7","sub8": "sub8","sub9": "sub9","sub10": "sub10",
            "sub11": "sub11","sub12": "sub12"}
        for (let e in params) {
        if (url.match(new RegExp(e + "="))) delete params[e];
            this.sdk_url_param = params;
            var sdk_data =
            "&lt;script src=&quot;" +
            this.url_data.sdk_url +
            "&quot; defer&gt;&lt;/script&gt;\n" +
            "&lt;script&gt;\n" +
            "document.addEventListener(&quot;rendered&quot;, function (e) {\n" +
            "var event_id=e.detail;\n" +
            "/* your code to do xhr request to your own api, your own api will call fraudAvoid api to validate the user. */\n" +
            "})&lt;/script&gt;";
            this.url_data.sdk_data = this.decodeHTMLEntities(sdk_data);
       
    }
    },
    decodeHTMLEntities(text) {
      var entities = [
        ["amp", "&"],
        ["apos", "'"],
        ["#x27", "'"],
        ["#x2F", "/"],
        ["#39", "'"],
        ["#47", "/"],
        ["lt", "<"],
        ["gt", ">"],
        ["nbsp", " "],
        ["quot", '"'],
      ];

      for (var i = 0, max = entities.length; i < max; ++i)
        text = text.replace(
          new RegExp("&" + entities[i][0] + ";", "g"),
          entities[i][1]
        );

      return text;
    },
    addUrl_Param(data) {
        this.sdk_data = false;
        var str1 = "?";
        var str3 = data.toLowerCase();
        var str4 = "={" + data + "}";

        for (var i = 0; i < this.url_data.sdk_url.length; i++) {
        if (this.url_data.sdk_url.charAt(i) == "?") {
            var str1 = "&";
        }
        }
        let sdk_url_text = str1.concat(str3.concat(str4));
        this.url_data.sdk_url = this.url_data.sdk_url.concat(sdk_url_text);
        var sdk_data =
        "&lt;script src=&quot;" +
        this.url_data.sdk_url +
        "&quot; defer&gt;&lt;/script&gt;\n" +
        "&lt;script&gt;\n" +
        "document.addEventListener(&quot;rendered&quot;, function (e) {\n" +
        "var event_id=e.detail;\n" +
        "/* your code to do xhr request to your own api, your own api will call fraudAvoid api to validate the user. */\n" +
        "})&lt;/script&gt;";
        this.url_data.sdk_data = this.decodeHTMLEntities(sdk_data);
        
        data.toLowerCase();
        this.checkButton(this.url_data.sdk_url)
        this.sdk_data = true;
    },
    copyToClipboard() {
      if(this.url_data.sdk_data){
        const el = document.createElement('textarea');
          el.addEventListener('focusin', e => e.stopPropagation());
          el.value = this.url_data.sdk_data;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
      Swal.fire({title: "Success!",text: "SDK Copied!!",icon: "success",confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
      });
    }else{
      Swal.fire({title: "Error!",text: "please select campaign from list and generate code.",icon: "error",confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 2000
      });
    }
      // this.errorNotify("Success", "URL Copied!!", "success");
    },
    },
}
</script>

<style>
.filter1:focus-within .multiselect__content-wrapper{
  width: 300px !important;
}
</style>
