let host = process.env.VUE_APP_BACKHOST
// let host = 'fraudavoid.makeapk.com'
let port =  process.env.VUE_APP_BACKPORT
module.exports = {
  host : host,
  port : port,
  backHost:process.env.VUE_APP_BACKHOST,
  campurl  : 'https://'+host+'/front/',
  zeromsurl  : 'https://'+host+'/front0/',
  sdkurl  : 'https://'+host+'/jsdk/',
  convurl  : 'https://'+host+'/conv/{EXTERNAL_ID}',
  customPostbackurl: 'https://'+host+'/convbyX',
  downloadMacroCsv: process.env.VUE_APP_BASEURL+'/download/macro/csv?',
}
