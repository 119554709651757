import api from './api'

export default {
    gettsOffer(data){
    return api.execute(`post`,`/ts/get`, data)
  },
  getOffer(data){
    return api.execute(`post`,`/offer/get`, data)
  },
  update(data){
    return api.execute(`post`,`/camp/modify`, data)
  },
  add(data){
    return api.execute(`post`,`/camp/add`, data)
  },
  chksettings(data){
      return api.execute(`post`,`/mastersetting/getsetting`, data)
  },
  get(data){
    if(window.localStorage.getItem("user") == 'csp'){
        return api.execute(`post`,`/csp/camp/get`, data)
    }
    else{return api.execute(`post`,`/camp/get`, data)}  
  },
  getTid(data){
    return api.execute(`post`,`/camp/getid`, data)
  },
  clone(data){
    return api.execute(`post`,`/camp/clone`, data)
  },
  delete(data){
    return api.execute(`post`,`/camp/remove`, data)
  },
  getManagerCamp(data){
    return api.execute(`post`,`/manager/campaigns`, data)
  },
  smartsearch(data){
    return api.execute(`post`,`/smartsearch`, data)
  },
  checkMacroCsv(data){
    return api.execute(`get`,`/check/macro/csv?`+data)
  },
  genrateCsvid(){
    return api.execute(`get`,`/generate/id`)
  },
  addCsv(param,file){
    return api.execute(`POST`,`/upload/macro/csv?`+param, file)
  },
  deleteMacroCsv(param){
    return api.execute(`DELETE`,`/delete/macro/csv?`+param)
  },
  cspuploadImage(id){
    return api.execute(`get`,`/csp/uploadurl?campid=`+id)
  }

}
